import React, { Component, Fragment } from 'react';
import styled from 'react-emotion';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { default as Image } from '@jetshop/ui/Image';
import Link from '../utility/Link';
import { imageObjConstruct } from '../utility/functions';

const CardWrapper = styled('div')`
  width: 100%;
  text-align: center;
`;

const Content = styled('div')`
  position: relative;
  display: inline-block;
  width: 370px;
  max-width: calc(100% - 20px);
  margin: -45px auto 0px auto;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  text-align: center;
  box-shadow: ${({ theme }) => theme.boxshadow};
  border-radius: ${({ theme }) => theme.radius.md};

  ${({ theme }) => theme.above.sm} {
    max-width: calc(100% - 30px);
  }
`;

const TopTitle = styled('h3')`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
`;

const Title = styled('h4')`
  color: ${({ theme }) => theme.colors.black};
  text-transform: none;
  font-weight: 700;
  text-decoration: none;
`;

const RenderContent = ({ image, imageArgs, topTitle, title }) => (
  <Fragment>
    {image && <Image {...imageArgs} />}

    <Content>
      {topTitle && <TopTitle className="sub-heading-size">{topTitle}</TopTitle>}
      {title && <Title className="heading-size">{title}</Title>}
    </Content>
  </Fragment>
);

class EntryCard extends Component {
  render() {
    let {
      numOfCols,
      sizes,
      title,
      topTitle,
      link,
      imageSrc,
      image,
      category
    } = this.props;

    if (!sizes) {
      switch (numOfCols) {
        case 1:
          sizes = [450, 1, 1, 1, 1400];
          break;
        case 2:
          sizes = [450, 1 / 2, 1 / 2, 1 / 2, 700];
          break;
        default:
          //Defaults to 3
          sizes = [450, 1 / 2, 1 / 3, 1 / 3, 470];
          break;
      }
    }

    const imageDefaults = {
      sizes: sizes,
      crop: true,
      cover: true,
      aspect: '7:5',
      gravity: 'centre'
    };
    const imageArgs = imageObjConstruct(imageSrc, image, imageDefaults);

    return (
      <CardWrapper>
        {category ? (
          <CategoryLink category={category}>
            <RenderContent
              image={image}
              imageArgs={imageArgs}
              topTitle={topTitle}
              title={title}
            />
          </CategoryLink>
        ) : (
          <Link {...link}>
            <RenderContent
              image={image}
              imageArgs={imageArgs}
              topTitle={topTitle}
              title={title}
            />
          </Link>
        )}
      </CardWrapper>
    );
  }
}

export default EntryCard;
